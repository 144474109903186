export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      textbox_container_class: string;
      id: string;
      thisCurr: TLM;
      otherCurr: TLM;

    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  /*Checked*/
  "Bim Volt": "eps_bim_battery_voltage", /* Asynchronous Battery Voltage */
  "Solar 0": "EPS_SACM_GROUP_0_SOLAR_PANEL_CURRENT_SENSE", /* Group 0 solar panel current sense */
  "Solar 1": "EPS_SACM_GROUP_1_SOLAR_PANEL_CURRENT_SENSE", /* Group 1 solar panel current sense */
  "Solar 2": "EPS_SACM_GROUP_2_SOLAR_PANEL_CURRENT_SENSE", /* Group 2 solar panel current sense */
  "Solar 3": "EPS_SACM_GROUP_3_SOLAR_PANEL_CURRENT_SENSE", /* Group 3 solar panel current sense */
  "Hub 5v Volt": "eps_bim_buck_bcr_hub_5_v_supply_voltage", /* Hub 5V supply voltage */
  "Hub c8051 Volt": "eps_bim_buck_bcr_hub_c_8051_internal_vdd_voltage", /* Hub C8051 internal VDD voltage */
  "Hub c8051 Temp": "eps_bim_buck_bcr_hub_c_8051_temperature_sense", /* Hub C8051 temperature sense */
  "Hub Reg Volt": "eps_bim_buck_bcr_hub_linear_regulator_voltage", /* Hub linear regulator voltage */
  "BCR A Voltage Sense": "eps_bim_buck_bcr_slice_a_battery_voltage_sense", /* Power slice battery voltage sense */
  "BCR A Current Sense": "eps_bim_buck_bcr_slice_a_charge_current_sense", /* Power slice charge current sense */
  "BCR B Voltage Sense": "eps_bim_buck_bcr_slice_b_battery_voltage_sense", /* Power slice battery voltage sense */
  "BCR B Current Sense": "eps_bim_buck_bcr_slice_b_charge_current_sense", /* Power slice charge current sense */
  "Power Slice A Solar Volt":"eps_bim_buck_bcr_slice_a_solar_voltage_sense", /* Power slice temp sensor */
  "Power Slice B Solar Volt":"eps_bim_buck_bcr_slice_b_solar_voltage_sense", /* Power slice temp sensor */

  "BIM Main Current": "eps_bim_main_current", /* Asynchronous current from the BCR and the battery to the test of the spacecraft */

  "p3v3 TLMi":"eps_supervisor_power_rail_p3v3_curr_tlm", /*  */
  "p3v3 TLM":"eps_supervisor_power_rail_p3v3_tlm", /*  */
  "p5v TLMi":"eps_supervisor_power_rail_p5v_curr_tlm", /*  */
  "p5v TLM":"eps_supervisor_power_rail_p5v_tlm", /*  */

  "ST A Pwr":"eps_spoc_dio_st_a_pwr_en", /*  */
  "ST B Pwr":"eps_spoc_dio_st_b_pwr_en", /*  */
  "Spoc Imu A Pwr En":"eps_spoc_dio_imu_a_pwr_en", /*  */
  "Spoc Imu B Pwr En":"eps_spoc_dio_imu_b_pwr_en", /*  */

  "Plim Pwr":"eps_spoc_dio_plim_main_pwr_en", /*  */
  
  "FSS Pwr En":"EPS_SPOC_DIO_FSS_PWR_EN", /* */
  "RW A Pwr En":"eps_spoc_dio_rw_a_pwr_en_agg", /* */
  "RW B Pwr En":"eps_spoc_dio_rw_b_pwr_en_agg", /* */
  "RW C Pwr En":"eps_spoc_dio_rw_c_pwr_en_agg", /* */
  "RW D Pwr En":"eps_spoc_dio_rw_d_pwr_en_agg", /* */

  "Spoc Piu Pwri":"eps_spoc_power_piu_i", /*  */
  "Spoc Piu Main Pwr":"eps_spoc_dio_piu_main_pwr_en", /*  */
  "Spoc FSS Curr":"eps_spoc_power_fss_i", /*  */

  "RW A Curr":"eps_spoc_power_rw_a_i", /*  */
  "RW A Volt":"eps_spoc_power_rw_a_v", /*  */
  "RW B Curr":"eps_spoc_power_rw_b_i", /*  */
  "RW B Volt":"eps_spoc_power_rw_b_v", /*  */
  "RW C Curr":"eps_spoc_power_rw_c_i", /*  */
  "RW C Volt":"eps_spoc_power_rw_c_v", /*  */
  "RW D Curr":"eps_spoc_power_rw_d_i", /*  */
  "RW D Volt":"eps_spoc_power_rw_d_v", /*  */

  "Spoc A Volt":"eps_supervisor_power_rail_spoca_vtlm", /*  */
  "Spoc A Curr":"eps_supervisor_power_rail_spoca_curr_tlm", /*  */
  "Spoc B Volt":"eps_supervisor_power_rail_spocb_vtlm", /*  */
  "Spoc B Curr":"eps_supervisor_power_rail_spocb_curr_tlm", /*  */

  "IMUA I":"eps_spoc_power_stim_a_i", /*  */
  "IMUB I":"eps_spoc_power_stim_b_i", /*  */

  "Spoc Cli":"eps_spoc_power_rail_cl_i", /* Current loop rail current */
  "Spoc Clv":"eps_spoc_power_rail_cl_v", /* Current loop rail voltage */
  "Spoc VHv":"eps_spoc_power_rail_vhold_v", /* Valve hold voltage */
  "Spoc VHi":"eps_spoc_power_rail_vhold_i", /* Valve hold current */
  "Spoc Csac En":"eps_spoc_dio_csac_en", /* CSAC enable state */
  "Spoc BUSi":"eps_spoc_power_rail_bus_i", /* Bus Current */
  "Spoc BUSv":"eps_spoc_power_rail_bus_v", /* Bus Voltage */


  "Hdrm Volt Tlm":"eps_supervisor_power_rail_vhdrm_tlm_agg", /*   */
  "Hdrm Curr Sen":"eps_supervisor_power_rail_hdrm_curr_sns_agg", /*   */
  "Plim 28v Curr":"eps_plim_power_rail_sense_28v_iso_i", /*   */
  "Plim 28v Volt":"eps_plim_power_rail_sense_28v_iso_v", /*   */

  "Lna Curr":"eps_supervisor_power_rail_lna_curr_tlm", /*   */
  "Hpa Curr":"eps_supervisor_power_rail_hpa_curr_tlm", /*   */
  "Hdrm Curr":"eps_supervisor_power_rail_hdrm_curr_sns", /*   */
  "Hdrm1 Volt":"eps_supervisor_power_rail_hdrm1out_tlm", /*   */
  "Hdrm2 Volt":"eps_supervisor_power_rail_hdrm2out_tlm", /*   */

  "Battery A Temp":"tcs_spoc_bim_battery_a_t", /*   */
  "Battery B Temp":"tcs_spoc_bim_battery_b_t", /*   */
  "Battery C Temp":"tcs_spoc_bim_battery_c_t", /*   */
  "Battery D Temp":"tcs_spoc_bim_battery_d_t", /*   */
  "Battery E Temp":"tcs_spoc_bim_battery_e_t", /*   */

  "Htr Batter A State":"eps_spoc_dio_bim_htr_battery_a_state", /*   */
  "Htr Batter B State":"eps_spoc_dio_bim_htr_battery_b_state", /*   */
  "Htr Batter C State":"eps_spoc_dio_bim_htr_battery_c_state", /*   */
  "Htr Batter D State":"eps_spoc_dio_bim_htr_battery_d_state", /*   */
  "Htr Batter E State":"eps_spoc_dio_bim_htr_battery_e_state", /*   */

  "Q7 En":"eps_spoc_dio_q7_pwr_en_agg", /* */
  "CSAC En":"EPS_SPOC_DIO_CSAC_EN", /* */

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",
  "Vehicle Mode Sh":"state_spinand0_last_cmd_vehicle_mode_sh", /* */
  "Eclipse": "gnc_env_est_output_eclipsestate_agg", /* */
}


export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}