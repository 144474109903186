import React from 'react'

interface PlaceHolderProp {
  data: {
    text: string;
  };
}

const PlaceHolder: React.FC<PlaceHolderProp> = ({data}) => {

//   const Example = styled('p')`
//   color: lightgreen;

//   & > strong {
//     color: hotpink;
//   }
  
// `
// const paragraph = css`
//   color: turquoise;

//   header & {
//     color: green;
//   }
// `

  return (
    <div>

      <p>{data.text}</p>

      {/* <WrapScrollable>
        <Litem>item</Litem>
      </WrapScrollable> */}

      {/* <Example>
      This is <strong>nested</strong>.
    </Example>

    <header>
      <p css={paragraph}>This is green since it's inside a header</p>
    </header>
    <p css={paragraph}>This is turquoise since it's not inside a header.</p> */}
    </div>
  )
}

export default PlaceHolder;
