import { css } from '@emotion/css';
export const commBox = css`
  width: 930px;
  // color: pink;

  & > div {
    width: 930px;

    & > div {
      width: 500px;

      &:nth-of-type(odd) {
        width: 420px;
      }

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;

export const spvrBox = css`
  width: 610px;

  & > div {
    width: 610px;

    & > div {
      width: 300px;

      & div:nth-of-type(2) {
        width: 150px;
      }
    }
  }
`;

export const rwBox = css`
width: 1000px;
height: 310px;

& > div {
  width: 1000px;
  height: 240px;
  flex-direction: column;

  & > div {
    width: 330px;

    & div:nth-of-type(2) {
      width: 150px;
    }
  }
}
`

export const BcrHub = css`
  width: 1410px;

  & > div {
    width: 1410px;

    & > div {
      width: 350px;

      & div:nth-of-type(2) {
        width: 180px;
      }
    }
  }
`;

export const dioBox = css`
  width: 1200px;
  height: 200px;

  & > div {
    width: 1200px;
    height: 120px;
    flex-direction: column;

    & > div {
      width: 400px;

      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6)
       {
        width: 270px;
      }

      &:nth-of-type(7),
      &:nth-of-type(8)
       {
        width: 350px;
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      {
        width: 290px;
      }

      & div:nth-of-type(2) {
        width: 160px;
      }
    }
  }
`;


export const bimBox = css`
width: 410px;
height: 540px;

& > div {
  width: 400px;
  height: 420px;
  flex-direction: column;

  & > div {
    width: 400px;

    & div:nth-of-type(2) {
      width: 150px;
    }
  }
}
`;

// Define a type for the class keys
export type ClassKey = 'commBox' | 'spvrBox' | 'dioBox' | 'bimBox' | 'BcrHub' | 'rwBox';