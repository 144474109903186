import React from "react";
import {  TLM} from "./utils";
import { Handle, Position } from "reactflow";
import { css } from '@emotion/css';

interface HeaterProp {
  data: {
    name: string;
    classname: string;
    pwr: TLM;
  };
}

const Heater: React.FC<HeaterProp> = ({ data }) => {

  const heaterContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `

  const getColorPwr = (value: TLM) => {
    if (value && value.telemetry === "On"){
      return "green"
    }
    else{
      return "rgb(100,100,100)"
    }
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ top: 20, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{ top: 20,opacity:0 }}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:20}}/>
      <Handle type="source" position={Position.Right} id="source-right-2" style={{opacity:0, top:20}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={heaterContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="162px" height="42px" viewBox="-0.5 -0.5 162 42">
            <defs />
            <g>
            <rect x="40" y="0" width="40" height="40" fill="rgb(38,38,38)" stroke={getColorPwr(data.pwr)} strokeWidth={6} pointerEvents="all" />
            <rect x="80" y="0" width="40" height="40" fill="rgb(38,38,38)" stroke={getColorPwr(data.pwr)} strokeWidth={6} pointerEvents="all" />
              <path d="M 120 19.76 L 160 20" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 0 19.66 L 40 19.9" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
            </g>
          </svg>

          <span>{data.name}</span>

      </div>
    </div>
  );
};

export default Heater;
