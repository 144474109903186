import { createContext, useContext } from 'react';
import { DateTime } from '@grafana/data';

interface TimeRange {
  from: DateTime;
  to: DateTime;
}

interface GUIValues {
  startDateTime: string;
  endDateTime: string;
  aggTime: string;
  aggFn: { fn: string };
  pbaggFn: { fn: string };
  pbisInPlayBack: boolean;
  Agg: boolean;
  Last: boolean;
  lastCount: number;
  pbaggTime: string;
  pbstopPlay: () => void;
}

interface ContextProps {
  timeRange: TimeRange;
  guiValues: {
    current: GUIValues;
  };
  oneWayLightTimeFieldValues: number;
}

const defaultContext: ContextProps = {
  // @ts-ignore
  timeRange: { from: new Date(), to: new Date() },
  guiValues: {
    current: {
      aggTime: '1s',
      aggFn: { fn: 'last' },
      Agg: false,
      Last: false,
      lastCount: 50,
  
      startDateTime: '2024-06-18 12:10:00',
      endDateTime: '2024-06-18 12:15:00',
      pbaggTime: '1s',
      pbaggFn: { fn: 'last'},
      pbisInPlayBack: false,
      pbstopPlay: () => {},
    }
  },
  oneWayLightTimeFieldValues: 0,
};

export const TimeRangeContext = createContext<ContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
