import { css } from '@emotion/css';

import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, nameToMne } from './utils';

import EPSPanel from '../module/EPSPanel';
import EpsTextBox from './EpsTextBox';
import BattCell from './BattCell';
import SolarCell from './SolarCell';
import PlaceHolder from './PlaceHolder';
import CustomEdge from './CustomEdge';
import BcrMain from '../module/BcrMain';
import BcrSub from '../module/BcrSub';
import Actuator from './Actuator';
import EpsHTb from './EpsHTb';
import LivePlot from './LivePlot';
import Heater from './Heater';
import VModeBox from './VModeBox';
import { useTimeRangeContext } from './TimeRangeContext';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;
  // ... other props if there are any
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValues
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: monospace;
}

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-epsPanel{
  z-index: 5 !important;
}

.react-flow__node-bcrMain{
  z-index: 6 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: var(--app-background);
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
`;

const nodeTypes = {epsPanel: EPSPanel,vmodeBox:VModeBox, liveplot:LivePlot,heater:Heater ,epsHTb:EpsHTb, actuator:Actuator,bcrMain:BcrMain,bcrSub:BcrSub ,battCell:BattCell,solarCell:SolarCell, epstextbox:EpsTextBox, placeHolder:PlaceHolder}
const edgeTypes = {customEdge: CustomEdge}

const EPSApp: React.FC<AppProps> = ({dbData,source, width, height, options,influxData}) => {
  
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      reactFlowInstance.fitView();
    }
  }
  ,[width, height]);

  function isWithinDifference(unixTimestamp: number, differenceInSeconds: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;
    // Compare and return
    
    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex], 30) : "";

    // Append the degree symbol to the unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC' || unit === 'celsius (°C)') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None'){
      unit = null
    }else if(unit === 'counts'){
      unit = null
    }
  
    // Special handling for 'WFI HV' and 'NFI HV'
    if (mne === 'plim_node_crc_errors') {
      cnvValue = parseFloat(cnvValue).toExponential(2);
    } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
      // Check if cnvValue is a number and convert/round it if so
      if (cnvValue.includes('.')) {
        // It's a float, parse and keep 2 decimal places
        cnvValue = parseFloat(cnvValue).toFixed(2);
      }
    }
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      let newMne = mne + "_agg"; // First try with _agg added
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source]);
  


  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'epsPanel', position:{x:575, y:50}, data:{'source':source}},
    { id: 'placeHolder1', type: 'placeHolder',parentNode:'Panel', position:{x:850, y:875}, data:{text:'To All Loads'}},
    { id: 'placeHolder2', type: 'placeHolder',parentNode:'Panel', position:{x:25, y:915}, data:{text:'Power Gen'}},
    { id: 'placeHolder3', type: 'placeHolder',parentNode:'Panel', position:{x:25, y:875}, data:{text:'Power Loads'}},
    { id: 'SolarCell4', type: 'solarCell',parentNode:'', position:{x:2300, y:1650}, data:{name:'', value:{'S3i': {'dbData':telemetryDictionary['Solar 3'], 'fname':'Solar 3'}}}},
    { id: 'SolarCell3', type: 'solarCell',parentNode:'', position:{x:2300, y:1050}, data:{name:'', value:{'S2i': {'dbData':telemetryDictionary['Solar 2'], 'fname':'Solar 2'}}}},
    
    { id: 'Liveplot', type: 'liveplot',parentNode:'Panel', position:{x:900, y:1475}, data:{mne:'eps_bim_battery_voltage_agg', sc:source}},
    { id: 'Liveplot2', type: 'liveplot',parentNode:'Panel', position:{x:900, y:1860}, data:{mne:'eps_bim_main_current_agg', sc:source}},
    
    { id: 'BattAHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1590}, data:{name:'BattA Htr', pwr:telemetryDictionary['Htr Batter A State']}},
    { id: 'BattBHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1710}, data:{name:'BattB Htr', pwr:telemetryDictionary['Htr Batter B State']}},
    { id: 'BattCHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1830}, data:{name:'BattC Htr', pwr:telemetryDictionary['Htr Batter C State']}},
    { id: 'BattDHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1940}, data:{name:'BattD Htr', pwr:telemetryDictionary['Htr Batter D State']}},
    { id: 'BattEHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:2060}, data:{name:'BattE Htr', pwr:telemetryDictionary['Htr Batter E State']}},

    { id: 'BIM', type: 'epsHTb',parentNode:'Panel', position:{x:400, y:1600}, data:{name:'Battery Interface Module','textbox_container_class':'bimBox', value:{'BattA Temp':{'dbData':telemetryDictionary['Battery A Temp'], 'fname':''},'BattB Temp':{'dbData':telemetryDictionary['Battery B Temp'], 'fname':''},'BattC Temp':{'dbData':telemetryDictionary['Battery C Temp'], 'fname':''},'BattD Temp':{'dbData':telemetryDictionary['Battery D Temp'], 'fname':''},'BattE Temp':{'dbData':telemetryDictionary['Battery E Temp'], 'fname':''},'Bim Curr *': {'dbData':telemetryDictionary['BIM Main Current'], 'fname':''},'Bim Volt *': {'dbData':telemetryDictionary['Bim Volt'], 'fname':'Bim Volt'}}}},
    { id: 'BattCell1', type: 'battCell',parentNode:'Panel', position:{x:270, y:1570}, data:{name:''}},
    { id: 'BCR Main', type: 'bcrMain',parentNode:'Panel', position:{x:52.5, y:990}, data:{}},
    { id: 'BCRPower2', type: 'epstextbox',parentNode:'BCR Main', position:{x:925, y:200}, data:{name:'Power Slice2', value:{'Charge Curr': {'dbData':telemetryDictionary['BCR B Current Sense'], 'fname':''}, 'Battery Volt': {'dbData':telemetryDictionary['BCR B Voltage Sense'], 'fname':''}, 'Solar Volt': {'dbData':telemetryDictionary['Power Slice B Solar Volt'], 'fname':''}}}},
    { id: 'BCRPower1', type: 'epstextbox',parentNode:'BCR Main', position:{x:100, y:200}, data:{name:'Power Slice1', value:{'Charge Curr': {'dbData':telemetryDictionary['BCR A Current Sense'], 'fname':''},'Battery Volt': {'dbData':telemetryDictionary['BCR A Voltage Sense'], 'fname':''}, 'Solar Volt': {'dbData':telemetryDictionary['Power Slice A Solar Volt'], 'fname':''}}}},
    { id: 'BCRHub', type: 'epsHTb',parentNode:'BCR Main', position:{x:57.5, y:50}, data:{name:'Hub Slice', textbox_container_class:'BcrHub',value:{'5Vv': {'dbData':telemetryDictionary['Hub 5v Volt'], 'fname':'Hub 5v Volt'},'REGv': {'dbData':telemetryDictionary['Hub Reg Volt'], 'fname':'Hub Reg Volt'},'8051v': {'dbData':telemetryDictionary['Hub c8051 Volt'], 'fname':'Hub c8051 Volt'},'8051t': {'dbData':telemetryDictionary['Hub c8051 Temp'], 'fname':'Hub c8051 Temp'}}}},

    { id: 'SolarCell2', type: 'solarCell',parentNode:'', position:{x:80, y:1650}, data:{name:'', value:{'S1i': {'dbData':telemetryDictionary['Solar 1'], 'fname':'Solar 1'}}}},
    { id: 'SolarCell1', type: 'solarCell',parentNode:'', position:{x:80, y:1050}, data:{name:'', value:{'S0i': {'dbData':telemetryDictionary['Solar 0'], 'fname':'Solar 0'}}}},

    { id: 'HDRM2', type: 'actuator',parentNode:'', position:{x:2350, y:595 }, data:{name:'HDRM2', value:{'En': {'dbData':telemetryDictionary['En2'], 'fname':''},'V': {'dbData':telemetryDictionary['Hdrm2 Volt'], 'fname':''}}}},

    { id: 'Tele', type: 'epstextbox',parentNode:'', position:{x:2250, y:680}, data:{name:'Comm','textbox_container_class':'commBox', value:{'Lna Curr': {'dbData':telemetryDictionary['Lna Curr'], 'fname':'LNA Current'},'HPA Curr':{'dbData':telemetryDictionary['Hpa Curr'], 'fname':''}}}},
    { id: 'GNCB', type: 'epstextbox',parentNode:'', position:{x:2250, y:350}, data:{name:'GNC B', value:{'ST B Power': {'dbData':telemetryDictionary['ST B Pwr'], 'fname':''},'IMU B Power': {'dbData':telemetryDictionary['Spoc Imu B Pwr En'], 'fname':''},'IMU B Curr': {'dbData':telemetryDictionary['IMUB I'], 'fname':''}}}},
    { id: 'SpocB', type: 'epstextbox',parentNode:'Panel', position:{x:1305, y:650}, data:{name:'Spoc B',thisCurr:telemetryDictionary['Spoc B Volt'],otherCurr:telemetryDictionary['Spoc A Volt'], 'textbox_container_class':'spocBox', value:{'Pwri': {'dbData':telemetryDictionary['Spoc B Curr'], 'fname':''},'Pwrv': {'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''}}}},
    { id: 'Spvr', type: 'epsHTb',parentNode:'Panel', position:{x:510, y:650}, data:{name:'Supervisor',textbox_container_class:"spvrBox", value:{'P3V3i': {'dbData':telemetryDictionary['p3v3 TLMi'], 'fname':''},'P3V3v': {'dbData':telemetryDictionary['p3v3 TLM'], 'fname':''},'P5Vi': {'dbData':telemetryDictionary['p5v TLMi'], 'fname':''},'P5Vv': {'dbData':telemetryDictionary['p5v TLM'], 'fname':''}}}},
    { id: 'Prop', type: 'epstextbox',parentNode:'', position:{x:2250, y:50}, data:{name:'Piu', value:{'Main Pwr':{'dbData':telemetryDictionary['Spoc Piu Main Pwr'], 'fname':''},'Curr': {'dbData':telemetryDictionary['Spoc Piu Pwri'], 'fname':''}}}},
    { id: 'SpocA', type: 'epstextbox',parentNode:'Panel', position:{x:25, y:650}, data:{name:'Spoc A', 'textbox_container_class':'spocBox',otherCurr:telemetryDictionary['Spoc B Volt'],thisCurr:telemetryDictionary['Spoc A Volt'],value:{'Pwri': {'dbData':telemetryDictionary['Spoc A Curr'], 'fname':''},'Pwrv': {'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''}}}},
    { id: 'Diode', type: 'epsHTb',parentNode:'Panel', position:{x:210, y:400}, data:{name:'Diode Board','textbox_container_class':'dioBox', value:{'BUSi': {'dbData':telemetryDictionary['Spoc BUSi'], 'fname':''},'BUSv': {'dbData':telemetryDictionary['Spoc BUSv'], 'fname':''},'CLi': {'dbData':telemetryDictionary['Spoc Cli'], 'fname':'Current loop rail current'},'CLv': {'dbData':telemetryDictionary['Spoc Clv'], 'fname':''},'VHi': {'dbData':telemetryDictionary['Spoc VHi'], 'fname':''},'VHv': {'dbData':telemetryDictionary['Spoc VHv'], 'fname':'Valve hold voltage'},'Q7 En': {'dbData':telemetryDictionary['Q7 En'], 'fname':''},'CSAC En': {'dbData':telemetryDictionary['CSAC En'], 'fname':''}}}},
    { id: 'HDRM1', type: 'actuator',parentNode:'', position:{x:150, y:595}, data:{name:'HDRM1', value:{'En': {'dbData':telemetryDictionary['En1'], 'fname':''}, 'V': {'dbData':telemetryDictionary['Hdrm1 Volt'], 'fname':''}}}},
    { id: 'HDRM', type: 'epstextbox',parentNode:'', position:{x:35, y:680}, data:{name:'HDRM', value:{'Curr': {'dbData':telemetryDictionary['Hdrm Curr Sen'], 'fname':''}, 'Volt': {'dbData':telemetryDictionary['Hdrm Volt Tlm'], 'fname':''}}}},
    { id: 'RW', type: 'epsHTb',parentNode:'Panel', position:{x:110, y:50}, data:{name:'RW',textbox_container_class:"rwBox", value:{'A Pwr En': {'dbData':telemetryDictionary['RW A Pwr En'], 'fname':''},'B Pwr En': {'dbData':telemetryDictionary['RW B Pwr En'], 'fname':''},'C Pwr En': {'dbData':telemetryDictionary['RW C Pwr En'], 'fname':''},'D Pwr En': {'dbData':telemetryDictionary['RW D Pwr En'], 'fname':''},'A Curr':{ 'dbData':telemetryDictionary['RW A Curr'], 'fname':''},'B Curr':{ 'dbData':telemetryDictionary['RW B Curr'], 'fname':''},'C Curr':{ 'dbData':telemetryDictionary['RW C Curr'], 'fname':''},'D Curr':{ 'dbData':telemetryDictionary['RW D Curr'], 'fname':''},'A Volt':{ 'dbData':telemetryDictionary['RW A Volt'], 'fname':''},'B Volt':{ 'dbData':telemetryDictionary['RW B Volt'], 'fname':''},'C Volt':{ 'dbData':telemetryDictionary['RW C Volt'], 'fname':''},'D Volt':{ 'dbData':telemetryDictionary['RW D Volt'], 'fname':''}}}},
    { id: 'FSS', type: 'epstextbox',parentNode:'Panel', position:{x:1130, y:50}, data:{name:'FSS',textbox_container_class:"fssBox", value:{'Pwr En': {'dbData':telemetryDictionary['FSS Pwr En'], 'fname':''}, "Curr": {'dbData':telemetryDictionary['Spoc FSS Curr'], 'fname':''}}}},
    
     { id: 'GNCA', type: 'epstextbox',parentNode:'', position:{x:35, y:350}, data:{name:'GNC A', value:{'ST A Power': {'dbData':telemetryDictionary['ST A Pwr'], 'fname':''},'IMU A Power': {'dbData':telemetryDictionary['Spoc Imu A Pwr En'], 'fname':''},'IMU A Curr': {'dbData':telemetryDictionary['IMUA I'], 'fname':''}}}},

     { id: 'Plim', type: 'epstextbox',parentNode:'', position:{x:35, y:50}, data:{name:'Plim', value:{'Main Pwr':{'dbData':telemetryDictionary['Plim Pwr'], 'fname':''},'28v Curr': {'dbData':telemetryDictionary['Plim 28v Curr'], 'fname':''},'28v Volt': {'dbData':telemetryDictionary['Plim 28v Volt'], 'fname':''}}}},
     { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:35, y:-55}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], eclipse:telemetryDictionary['Eclipse']}},

    ]);

  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Panel', type: 'epsPanel', position:{x:575, y:50}, data:{'source':source}},
      { id: 'placeHolder1', type: 'placeHolder',parentNode:'Panel', position:{x:850, y:875}, data:{text:'To All Loads'}},
      { id: 'placeHolder2', type: 'placeHolder',parentNode:'Panel', position:{x:25, y:915}, data:{text:'Power Gen'}},
      { id: 'placeHolder3', type: 'placeHolder',parentNode:'Panel', position:{x:25, y:875}, data:{text:'Power Loads'}},
      { id: 'SolarCell4', type: 'solarCell',parentNode:'', position:{x:2300, y:1650}, data:{name:'', value:{'S3i': {'dbData':telemetryDictionary['Solar 3'], 'fname':'Solar 3'}}}},
      { id: 'SolarCell3', type: 'solarCell',parentNode:'', position:{x:2300, y:1050}, data:{name:'', value:{'S2i': {'dbData':telemetryDictionary['Solar 2'], 'fname':'Solar 2'}}}},
      
      { id: 'Liveplot', type: 'liveplot',parentNode:'Panel', position:{x:900, y:1475}, data:{mne:'eps_bim_battery_voltage_agg', sc:source}},
      { id: 'Liveplot2', type: 'liveplot',parentNode:'Panel', position:{x:900, y:1860}, data:{mne:'eps_bim_main_current_agg', sc:source}},
      
      { id: 'BattAHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1590}, data:{name:'BattA Htr', pwr:telemetryDictionary['Htr Batter A State']}},
      { id: 'BattBHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1710}, data:{name:'BattB Htr', pwr:telemetryDictionary['Htr Batter B State']}},
      { id: 'BattCHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1830}, data:{name:'BattC Htr', pwr:telemetryDictionary['Htr Batter C State']}},
      { id: 'BattDHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:1940}, data:{name:'BattD Htr', pwr:telemetryDictionary['Htr Batter D State']}},
      { id: 'BattEHtr', type: 'heater',parentNode:'Panel', position:{x:25, y:2060}, data:{name:'BattE Htr', pwr:telemetryDictionary['Htr Batter E State']}},

      { id: 'BIM', type: 'epsHTb',parentNode:'Panel', position:{x:400, y:1600}, data:{name:'Battery Interface Module','textbox_container_class':'bimBox', value:{'BattA Temp':{'dbData':telemetryDictionary['Battery A Temp'], 'fname':''},'BattB Temp':{'dbData':telemetryDictionary['Battery B Temp'], 'fname':''},'BattC Temp':{'dbData':telemetryDictionary['Battery C Temp'], 'fname':''},'BattD Temp':{'dbData':telemetryDictionary['Battery D Temp'], 'fname':''},'BattE Temp':{'dbData':telemetryDictionary['Battery E Temp'], 'fname':''},'Bim Curr *': {'dbData':telemetryDictionary['BIM Main Current'], 'fname':''},'Bim Volt *': {'dbData':telemetryDictionary['Bim Volt'], 'fname':'Bim Volt'}}}},
      { id: 'BattCell1', type: 'battCell',parentNode:'Panel', position:{x:270, y:1570}, data:{name:''}},
      { id: 'BCR Main', type: 'bcrMain',parentNode:'Panel', position:{x:52.5, y:990}, data:{}},
      { id: 'BCRPower2', type: 'epstextbox',parentNode:'BCR Main', position:{x:925, y:200}, data:{name:'Power Slice2', value:{'Charge Curr': {'dbData':telemetryDictionary['BCR B Current Sense'], 'fname':''}, 'Battery Volt': {'dbData':telemetryDictionary['BCR B Voltage Sense'], 'fname':''}, 'Solar Volt': {'dbData':telemetryDictionary['Power Slice B Solar Volt'], 'fname':''}}}},
      { id: 'BCRPower1', type: 'epstextbox',parentNode:'BCR Main', position:{x:100, y:200}, data:{name:'Power Slice1', value:{'Charge Curr': {'dbData':telemetryDictionary['BCR A Current Sense'], 'fname':''},'Battery Volt': {'dbData':telemetryDictionary['BCR A Voltage Sense'], 'fname':''}, 'Solar Volt': {'dbData':telemetryDictionary['Power Slice A Solar Volt'], 'fname':''}}}},
      { id: 'BCRHub', type: 'epsHTb',parentNode:'BCR Main', position:{x:57.5, y:50}, data:{name:'Hub Slice', textbox_container_class:'BcrHub',value:{'5Vv': {'dbData':telemetryDictionary['Hub 5v Volt'], 'fname':'Hub 5v Volt'},'REGv': {'dbData':telemetryDictionary['Hub Reg Volt'], 'fname':'Hub Reg Volt'},'8051v': {'dbData':telemetryDictionary['Hub c8051 Volt'], 'fname':'Hub c8051 Volt'},'8051t': {'dbData':telemetryDictionary['Hub c8051 Temp'], 'fname':'Hub c8051 Temp'}}}},
      { id: 'SolarCell2', type: 'solarCell',parentNode:'', position:{x:80, y:1650}, data:{name:'', value:{'S1i': {'dbData':telemetryDictionary['Solar 1'], 'fname':'Solar 1'}}}},
      { id: 'SolarCell1', type: 'solarCell',parentNode:'', position:{x:80, y:1050}, data:{name:'', value:{'S0i': {'dbData':telemetryDictionary['Solar 0'], 'fname':'Solar 0'}}}},
 
      { id: 'HDRM2', type: 'actuator',parentNode:'', position:{x:2350, y:595 }, data:{name:'HDRM2', value:{'En': {'dbData':telemetryDictionary['En2'], 'fname':''},'V': {'dbData':telemetryDictionary['Hdrm2 Volt'], 'fname':''}}}},
 
      { id: 'Tele', type: 'epstextbox',parentNode:'', position:{x:2250, y:680}, data:{name:'Comm','textbox_container_class':'commBox', value:{'Lna Curr': {'dbData':telemetryDictionary['Lna Curr'], 'fname':'LNA Current'},'HPA Curr':{'dbData':telemetryDictionary['Hpa Curr'], 'fname':''}}}},
      { id: 'GNCB', type: 'epstextbox',parentNode:'', position:{x:2250, y:350}, data:{name:'GNC B', value:{'ST B Power': {'dbData':telemetryDictionary['ST B Pwr'], 'fname':''},'IMU B Power': {'dbData':telemetryDictionary['Spoc Imu B Pwr En'], 'fname':''},'IMU B Curr': {'dbData':telemetryDictionary['IMUB I'], 'fname':''}}}},
      { id: 'SpocB', type: 'epstextbox',parentNode:'Panel', position:{x:1305, y:650}, data:{name:'Spoc B',thisCurr:telemetryDictionary['Spoc B Volt'],otherCurr:telemetryDictionary['Spoc A Volt'], 'textbox_container_class':'spocBox', value:{'Pwri': {'dbData':telemetryDictionary['Spoc B Curr'], 'fname':''},'Pwrv': {'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''}}}},
      { id: 'Spvr', type: 'epsHTb',parentNode:'Panel', position:{x:510, y:650}, data:{name:'Supervisor',textbox_container_class:"spvrBox", value:{'P3V3i': {'dbData':telemetryDictionary['p3v3 TLMi'], 'fname':''},'P3V3v': {'dbData':telemetryDictionary['p3v3 TLM'], 'fname':''},'P5Vi': {'dbData':telemetryDictionary['p5v TLMi'], 'fname':''},'P5Vv': {'dbData':telemetryDictionary['p5v TLM'], 'fname':''}}}},
      { id: 'Prop', type: 'epstextbox',parentNode:'', position:{x:2250, y:50}, data:{name:'Piu', value:{'Main Pwr':{'dbData':telemetryDictionary['Spoc Piu Main Pwr'], 'fname':''},'Curr': {'dbData':telemetryDictionary['Spoc Piu Pwri'], 'fname':''}}}},
      { id: 'SpocA', type: 'epstextbox',parentNode:'Panel', position:{x:25, y:650}, data:{name:'Spoc A', 'textbox_container_class':'spocBox',otherCurr:telemetryDictionary['Spoc B Volt'],thisCurr:telemetryDictionary['Spoc A Volt'],value:{'Pwri': {'dbData':telemetryDictionary['Spoc A Curr'], 'fname':''},'Pwrv': {'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''}}}},
      { id: 'Diode', type: 'epsHTb',parentNode:'Panel', position:{x:210, y:400}, data:{name:'Diode Board','textbox_container_class':'dioBox', value:{'BUSi': {'dbData':telemetryDictionary['Spoc BUSi'], 'fname':''},'BUSv': {'dbData':telemetryDictionary['Spoc BUSv'], 'fname':''},'CLi': {'dbData':telemetryDictionary['Spoc Cli'], 'fname':'Current loop rail current'},'CLv': {'dbData':telemetryDictionary['Spoc Clv'], 'fname':''},'VHi': {'dbData':telemetryDictionary['Spoc VHi'], 'fname':''},'VHv': {'dbData':telemetryDictionary['Spoc VHv'], 'fname':'Valve hold voltage'},'Q7 En': {'dbData':telemetryDictionary['Q7 En'], 'fname':''},'CSAC En': {'dbData':telemetryDictionary['CSAC En'], 'fname':''}}}},
      { id: 'HDRM1', type: 'actuator',parentNode:'', position:{x:150, y:595}, data:{name:'HDRM1', value:{'En': {'dbData':telemetryDictionary['En1'], 'fname':''}, 'V': {'dbData':telemetryDictionary['Hdrm1 Volt'], 'fname':''}}}},
      { id: 'HDRM', type: 'epstextbox',parentNode:'', position:{x:35, y:680}, data:{name:'HDRM', value:{'Curr': {'dbData':telemetryDictionary['Hdrm Curr Sen'], 'fname':''}, 'Volt': {'dbData':telemetryDictionary['Hdrm Volt Tlm'], 'fname':''}}}},
      { id: 'RW', type: 'epsHTb',parentNode:'Panel', position:{x:110, y:50}, data:{name:'RW',textbox_container_class:"rwBox", value:{'A Pwr En': {'dbData':telemetryDictionary['RW A Pwr En'], 'fname':''},'B Pwr En': {'dbData':telemetryDictionary['RW B Pwr En'], 'fname':''},'C Pwr En': {'dbData':telemetryDictionary['RW C Pwr En'], 'fname':''},'D Pwr En': {'dbData':telemetryDictionary['RW D Pwr En'], 'fname':''},'A Curr':{ 'dbData':telemetryDictionary['RW A Curr'], 'fname':''},'B Curr':{ 'dbData':telemetryDictionary['RW B Curr'], 'fname':''},'C Curr':{ 'dbData':telemetryDictionary['RW C Curr'], 'fname':''},'D Curr':{ 'dbData':telemetryDictionary['RW D Curr'], 'fname':''},'A Volt':{ 'dbData':telemetryDictionary['RW A Volt'], 'fname':''},'B Volt':{ 'dbData':telemetryDictionary['RW B Volt'], 'fname':''},'C Volt':{ 'dbData':telemetryDictionary['RW C Volt'], 'fname':''},'D Volt':{ 'dbData':telemetryDictionary['RW D Volt'], 'fname':''}}}},
      { id: 'FSS', type: 'epstextbox',parentNode:'Panel', position:{x:1130, y:50}, data:{name:'FSS',textbox_container_class:"fssBox", value:{'Pwr En': {'dbData':telemetryDictionary['FSS Pwr En'], 'fname':''}, "Curr": {'dbData':telemetryDictionary['Spoc FSS Curr'], 'fname':''}}}},
      
       { id: 'GNCA', type: 'epstextbox',parentNode:'', position:{x:35, y:350}, data:{name:'GNC A', value:{'ST A Power': {'dbData':telemetryDictionary['ST A Pwr'], 'fname':''},'IMU A Power': {'dbData':telemetryDictionary['Spoc Imu A Pwr En'], 'fname':''},'IMU A Curr': {'dbData':telemetryDictionary['IMUA I'], 'fname':''}}}},

       { id: 'Plim', type: 'epstextbox',parentNode:'', position:{x:35, y:50}, data:{name:'Plim', value:{'Main Pwr':{'dbData':telemetryDictionary['Plim Pwr'], 'fname':''},'28v Curr': {'dbData':telemetryDictionary['Plim 28v Curr'], 'fname':''},'28v Volt': {'dbData':telemetryDictionary['Plim 28v Volt'], 'fname':''}}}},
       { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:35, y:-55}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], eclipse:telemetryDictionary['Eclipse'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]
  }

  useEffect(() => {
    if (!guiValues.current.pbisInPlayBack) return;
  
    let isCancelled = false;
  
    const processData = async () => {
      // Initialize an empty object to store telemetry data
      let aggregatedData: TelemetryDictionary = {};
      const totalLoops = Object.keys(influxData).length;
  
      for (let i = 0; i < totalLoops; i++) {
        // Check if the process should stop
        if (!guiValues.current.pbisInPlayBack || isCancelled) {
          break;
        }

        const key = Object.keys(influxData)[i];
        const selectedData = influxData[key];
          pbTimeRef.current = key;
          const newValue = (i + 1)/totalLoops;
          pbLoopRef.current = newValue;

  
        // Loop through each item in selectedData and update the aggregatedData object
        selectedData.forEach(item => {
          // If the key already exists, update the telemetry value
          if (aggregatedData[item.name]) {
            if (item.cnvValue != '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: "",
              unit: "",
              spacecraft: "",
              live: "rgb(68, 169, 241)",
            };
          }
        });
  
        // Create nodes from the aggregatedData object
        const newNodes = createNodes(aggregatedData);
        setNodes(newNodes);
  
        // Log the current loop progress
        //console.log(`${i + 1}/${totalLoops}`);
  
        // Wait for 2 seconds before processing the next item
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      guiValues.current.pbstopPlay()
      //console.log('Playback completed');
    };
  
    processData();
  
    // Cleanup function to cancel the loop if pbisInPlayBack changes
    return () => {
      isCancelled = true;
    };
  }, [influxData, guiValues.current.pbisInPlayBack]);

  useEffect(() => {
    //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
    if (guiValues.current.pbisInPlayBack) return;
    const telemetryDictionary1 = createTelemetryDictionary();
  
    const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
    setNodes(newNodes);
  }, [dbData, createTelemetryDictionary]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const initialEdges = [
  {id: 'Solar0ToBCR', source: 'SolarCell1',sourceHandle: 'source-right', target: 'BCRHub',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge2', source: 'SolarCell2',sourceHandle: 'source-right', target: 'BCRHub',targetHandle:'target-left-2', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'Solar3ToBCR', source: 'SolarCell3',sourceHandle: 'source-left', target: 'BCRHub',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge4', source: 'SolarCell4',sourceHandle: 'source-left', target: 'BCRHub',targetHandle:'target-right-2', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge5', source: 'BCRHub',sourceHandle: 'source-left-2', target: 'BCRPower1',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge6', source: 'BCRHub',sourceHandle: 'source-right-2', target: 'BCRPower2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge7', source: 'BCRPower1',sourceHandle: 'source-right', target: 'BCRHub',targetHandle:'target-bottom-4', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge8', source: 'BCRPower2',sourceHandle: 'source-left', target: 'BCRHub',targetHandle:'target-bottom-5', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  
  {id: 'edge9', source: 'BCRHub',sourceHandle: 'source-bottom', target: 'BIM',targetHandle:'target-top-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow, markerStart:arrow,zIndex:10},

  {id: 'edge10', source: 'Spvr',sourceHandle: 'source-left', target: 'SpocA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge11', source: 'Spvr',sourceHandle: 'source-right', target: 'SpocB',targetHandle:'target-left', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge12', source: 'SpocA',sourceHandle: 'source-top', target: 'Diode',targetHandle:'target-left-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge13', source: 'SpocB',sourceHandle: 'source-top', target: 'Diode',targetHandle:'target-right-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge14', source: 'Diode',sourceHandle: 'source-left-3', target: 'Plim',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge15', source: 'Diode',sourceHandle: 'source-right-3', target: 'Prop',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},

  {id: 'edge16', source: 'SpocA',sourceHandle: 'source-left', target: 'GNCA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge17', source: 'SpocB',sourceHandle: 'source-right', target: 'GNCB',targetHandle:'target-left', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},

  {id: 'edge18', source: 'Spvr',sourceHandle: 'source-bottom-2', target: 'Tele',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge19', source: 'Spvr',sourceHandle: 'source-bottom-1', target: 'HDRM',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},

  {id: 'BcrToSpvr1', source: 'BCRHub',sourceHandle: 'source-top', target: 'Spvr',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
  {id: 'edge21', source: 'HDRM1',sourceHandle: 'source-left', target: 'HDRM2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'},zIndex:10},
  {id: 'edge22', source: 'BattAHtr',sourceHandle: 'source-right-1', target: 'BattCell1',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter A State']}},
  {id: 'edge23', source: 'BattBHtr',sourceHandle: 'source-right-1', target: 'BattCell1',targetHandle:'target-left-2', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter B State']}},
  {id: 'edge24', source: 'BattCHtr',sourceHandle: 'source-right-1', target: 'BattCell1',targetHandle:'target-left-3', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter C State']}},
  {id: 'edge25', source: 'BattDHtr',sourceHandle: 'source-right-1', target: 'BattCell1',targetHandle:'target-left-4', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter D State']}},
  {id: 'edge26', source: 'BattEHtr',sourceHandle: 'source-right-1', target: 'BattCell1',targetHandle:'target-left-5', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter E State']}},
  {id: 'edge27', source: 'Diode',sourceHandle: 'source-top-1', target: 'RW',targetHandle:'target-right-4', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter E State']}},
  {id: 'edge28', source: 'Diode',sourceHandle: 'source-top-2', target: 'FSS',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10, data:{power:telemetryDictionary['Htr Batter E State']}},

 ];

  const proOptions = { hideAttribution: true };
  
  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          nodes={nodes}
          onInit={onInit}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>
    </ReactFlowProvider>
  );

}

export default EPSApp;







