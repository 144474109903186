import { css } from '@emotion/css';
import React from 'react';

interface EPSPanelProp {
  data: {
    source: string;
  };
}

const EPSPanel: React.FC<EPSPanelProp> = ({data}) => {

  let color;

  if (data.source.includes('escg')) {
    color = 'gold';
  } else if (data.source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  } else {
    color = 'gray';
  }

  const panelStyle = css`
    width: 1630px;
    height: 2250px;
    border: 6px solid ${color};
    background-color: rgb(38, 38, 38);
    text-align: left;
  `;

  return (
    <div className={panelStyle}></div>
  )
}

export default EPSPanel;
