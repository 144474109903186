import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && (data.power.telemetry === '1' || data.power.telemetry === 'High'|| data.power.telemetry === 'On' || (data.power.telemetry ? data.power.telemetry.includes('On') : false)) ? 'green' : 'white';

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }

  let finaledge;

  if (id === 'Solar0ToBCR'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+60},${sourceY}Q ${sourceX+65},${sourceY} ${sourceX+65},${sourceY-5}L ${sourceX+65},${targetY+5}Q ${sourceX+65},${targetY} ${sourceX+70},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'Solar3ToBCR'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX-60},${sourceY}Q ${sourceX-65},${sourceY} ${sourceX-65},${sourceY-5}L ${sourceX-65},${targetY+5}Q ${sourceX-65},${targetY} ${sourceX-70},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'ICB EESA Power'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+75},${sourceY}Q ${sourceX+80},${sourceY} ${sourceX+80},${sourceY-5}L ${sourceX+80}, ${targetY-45}Q ${sourceX+80}, ${targetY-50} ${sourceX+75}, ${targetY-50}L ${targetX+5}, ${targetY-50}Q ${targetX}, ${targetY-50} ${targetX}, ${targetY-45}L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'SpvrToHDRM1'){
    // const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-65}Q ${sourceX},${sourceY-70} ${sourceX-5},${sourceY-70}L ${targetX-55},${sourceY-70}Q ${targetX-60},${sourceY-70} ${targetX-60},${sourceY-65}L ${targetX-60},${targetY-5}Q ${targetX-60},${targetY} ${targetX-55},${targetY}L${targetX} ${targetY}`;
    //const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY+65}Q ${sourceX},${sourceY+70} ${sourceX-5},${sourceY+70}L ${sourceX-200},${sourceY+70}Q ${sourceX-205},${sourceY+70} ${sourceX-205},${sourceY+75}L ${sourceX-205},${sourceY+200}Q ${sourceX-205},${sourceY+205} ${sourceX-210},${sourceY+205}L${targetX+200} ${sourceY+205} Q ${targetX+195},${sourceY+205} ${targetX+195},${sourceY+210} L${targetX+195} ${targetY-5} Q ${targetX+195},${targetY} ${targetX+190},${targetY} L${targetX} ${targetY}`;
    // console.log(sourceX, sourceY, targetX, targetY); 
    // console.log(edgePath)
    finaledge = edgePath;
  }
  else if (id === 'SpvrToHDRM2'){
    //const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY+45}Q ${sourceX},${sourceY+50} ${sourceX+5},${sourceY+50}L ${sourceX+200},${sourceY+50}Q ${sourceX+205},${sourceY+50} ${sourceX+205},${sourceY+55}L ${sourceX+205},${sourceY+200}Q ${sourceX+205},${sourceY+205} ${sourceX+210},${sourceY+205}L${targetX-200} ${sourceY+205} Q ${targetX-195},${sourceY+205} ${targetX-195},${sourceY+210} L${targetX-195} ${targetY-5} Q ${targetX-195},${targetY} ${targetX-190},${targetY} L${targetX} ${targetY}`;
    // console.log(sourceX, sourceY, targetX, targetY); 
    // console.log(edgePath)
    finaledge = edgePath;
  }
  else if (id === 'BcrToSpvr'){
    // const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-565}Q ${sourceX},${sourceY-570} ${sourceX-5},${sourceY-570}L ${sourceX-400},${sourceY-570}Q ${sourceX-405},${sourceY-570} ${sourceX-405},${sourceY-575}L ${sourceX-405},${sourceY-750}Q ${sourceX-405},${sourceY-755} ${sourceX-410},${sourceY-755}L${targetX+5} ${sourceY-755} Q ${targetX},${sourceY-755} ${targetX},${sourceY-760} L${targetX} ${targetY}`;
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY-865}Q ${sourceX},${sourceY-870} ${sourceX-5},${sourceY-870}L${targetX+5} ${sourceY-870} Q ${targetX},${sourceY-870} ${targetX},${sourceY-875} L${targetX} ${targetY}`;
    // console.log(sourceX, sourceY, targetX, targetY); 
    // console.log(edgePath)
    finaledge = edge;
  }
  else if (id === 'edge21'){
    sourceY = sourceY + 340;
    targetY = targetY + 340;
    targetX = targetX + 300;
    const edge = `M${sourceX} ${sourceY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge9'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${sourceY+250}Q ${sourceX},${sourceY+255} ${sourceX-5},${sourceY+255}L ${targetX+5},${sourceY+255}Q ${targetX},${sourceY+255} ${targetX},${sourceY+260} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle} />
      <EdgeLabelRenderer>
        <div style={{position: 'relative',}}>
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
