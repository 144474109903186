import { css } from '@emotion/css';
export const spocBox = css`
  width: 300px;

  & > div {
    width: 300px;

    & > div {
      width: 295px;

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;

export const bcrSubSubTextbox = css`
  width: 325px;
  & > div {
    width:325px;

    & > div {
      width: 320px;

      & div:nth-of-type(2) {
        width: 180px;
      }
    }
  }
`;

export const fssBox = css`
  width: 380px;
  `


// Define a type for the class keys
export type ClassKey = 'spocBox' | 'bcrSubSubTextbox' | 'fssBox';